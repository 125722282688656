import { useMutation } from "@apollo/client";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMIN_ORDERS, UPDATE_BOOKING } from "../services/bookings.service";
import { Booking } from "../types";

interface Props {
  booking: Booking;
  today: Moment;
  refetch?: () => void;
  bookings: number;
  isMobile: boolean;
  position?: number;
  updateLocalBookings?: (position: number | undefined, action: string) => void;
  isGroupOrder: boolean;
}

export default function BookingCard({
  booking,
  today,
  refetch,
  bookings,
  isMobile,
  position,
  updateLocalBookings,
  isGroupOrder,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [updateBooking] = useMutation<{ updateBooking: { ok: boolean } }>(
    UPDATE_BOOKING,
    {
      refetchQueries: [ADMIN_ORDERS],
    }
  );
  const [loading, setLoading] = useState<boolean>(false);

  const isGrabNGo = booking.cubbi.use === "A_4";
  const notDelivered =
    !booking.deliveredAt &&
    (isGroupOrder ? booking.cubbi.groupOrder : !booking.cubbi.groupOrder); //deliver
  const move =
    moment(booking.endAt).isSameOrBefore(today, "day") &&
    booking.deliveredAt &&
    !isGrabNGo &&
    !booking.completed &&
    (isGroupOrder ? booking.cubbi.groupOrder : !booking.cubbi.groupOrder); //move
  const delivered =
    booking.deliveredAt &&
    moment(booking.deliveredAt).isSame(today, "day") &&
    (isGroupOrder ? booking.cubbi.groupOrder : !booking.cubbi.groupOrder); //delivered
  const remove =
    moment(booking.endAt).isSameOrBefore(today, "day") &&
    booking.deliveredAt &&
    isGrabNGo &&
    !booking.completed &&
    (isGroupOrder ? booking.cubbi.groupOrder : !booking.cubbi.groupOrder) &&
    bookings === 1;

  async function markAsMoved() {
    setLoading(true);
    try {
      await updateBooking({
        variables: {
          id: booking.id,
          input: {
            completed: true,
            moved: true,
          },
        },
      });
      if (updateLocalBookings) {
        updateLocalBookings(position, "moved");
      }
      if (refetch) {
        refetch();
      }
    } catch (e) {
      alert("Error marking as moved");
    }
    setLoading(false);
  }
  async function handleMarkAsRemoved() {
    setLoading(true);
    try {
      await updateBooking({
        variables: {
          id: booking.id,
          input: {
            completed: true,
            moved: true,
          },
        },
      });
      if (updateLocalBookings) {
        updateLocalBookings(position, "removed");
      }
      if (refetch) {
        refetch();
      }
    } catch (e) {
      alert("Error marking as removed");
    }
    setLoading(false);
  }
  async function handleMarkAsDelivered() {
    setLoading(true);
    try {
      const today = moment();
      await updateBooking({
        variables: {
          id: booking.id,
          input: {
            deliveredAt: today.toISOString(true),
            sendText: true,
          },
        },
      });
      if (updateLocalBookings) {
        updateLocalBookings(position, "deliver");
      }
      if (refetch) {
        refetch();
      }
    } catch (e) {
      alert("Error marking as delivered");
    }
    setLoading(false);
  }

  const fullName =
    booking.owner?.firstName && booking.owner.lastName
      ? booking.owner.firstName + " " + booking.owner.lastName
      : booking.owner?.email ?? "";

  if (move) {
    return (
      <div className="py-4">
        <div className="flex flex-col bg-white rounded-md shadow-md lg:w-[442px]">
          <div className="w-2 bg-warning-600 flex-shrink-0" />
          <div className="flex w-full">
            <div className="flex flex-row justify-between w-full px-4">
              <div className="flex-wrap w-1/2">
                {booking.bookingItems.map((e, i) => {
                  return (
                    <div key={i}>
                      <span className="text-[#2D3142] font-bold">{e.name}</span>
                      <span> x{e.quantity}</span>
                    </div>
                  );
                })}
              </div>
              <div className="text-warning-800 bg-warning-50 py-[2px] px-[6px] h-7 rounded-md font-medium">
                Move to fridge
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between text-graphite-900 px-4 w-full pt-2">
            <div className="w-1/2">
              <span className="font-medium">Booking ID: </span>
              <span>{booking.id}</span>
            </div>
            <div className="w-1/2">
              <span className="font-medium">Type: </span>
              <span>{isGrabNGo ? "Grab n Go" : "PreStock"}</span>
            </div>
          </div>
          <div className="flex flex-row justify-between text-graphite-900 px-4 w-full pb-4">
            <div className="w-1/2">
              <span className="font-medium">Order ID:</span>
              <span> {booking.orderId}</span>
            </div>
            <div className="w-1/2">
              <span className="font-medium">Name: </span>
              <span>{fullName}</span>
            </div>
          </div>
          <div className="px-4 pb-4">
            <button
              disabled={loading}
              className="flex items-center text-white bg-[#D57700] justify-center px-3 py-3 rounded-md w-full border border-gray"
              onClick={markAsMoved}
            >
              {loading ? "Loading..." : "Mark as moved"}
            </button>
          </div>
        </div>
      </div>
    );
  } else if (notDelivered) {
    return (
      <div className="py-4">
        <div className="flex flex-col bg-white rounded-md shadow-md lg:w-[442px]">
          <div className="w-2 bg-blue-600 flex-shrink-0" />
          <div className="flex flex-row justify-between w-full px-4">
            <div className="flex">
              <div className="flex-wrap">
                {booking.bookingItems.map((e, i) => {
                  return (
                    <div key={i}>
                      <span className="text-[#2D3142] font-bold">{e.name}</span>
                      <span> x{e.quantity}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="text-blue-800 bg-blue-50 py-[2px] px-[6px] h-7 rounded-md font-medium">
              Deliver
            </div>
          </div>
          <div className="flex flex-row justify-between text-graphite-900 px-4 w-full pt-2">
            <div className="w-1/2">
              <span className="font-medium">Booking ID: </span>
              <span>{booking.id}</span>
            </div>
            <div className="w-1/2">
              <span className="font-medium">Type: </span>
              <span>{isGrabNGo ? "Grab n Go" : "PreStock"}</span>
            </div>
          </div>
          <div className="flex flex-row justify-between text-graphite-900 px-4 w-full pb-4">
            <div className="w-1/2">
              <span className="font-medium">Order ID:</span>
              <span> {booking.orderId}</span>
            </div>
            <div className="w-1/2">
              <span className="font-medium">Name: </span>
              <span>{fullName}</span>
            </div>
          </div>
          <div className="px-4 pb-4 w-full">
            <button
              disabled={loading}
              className="cursor-pointer flex items-center text-white bg-blue-600 hover:bg-blue-700 justify-center px-3 py-3 rounded-md w-full border border-gray"
              onClick={handleMarkAsDelivered}
            >
              {loading ? "Loading..." : "Mark as delivered"}
            </button>
            <div className="py-1" />
            <button
              disabled={loading}
              className="cursor-pointer flex items-center text-graphite-700 border border-graphite-200 bg-white hover:bg-gray-100 justify-center px-3 py-3 rounded-md w-full border border-gray"
              onClick={() =>
                navigate(`/booking-reassign`, {
                  state: { fridge: location.state.fridge, booking: booking },
                })
              }
            >
              Can't deliver? Reassign
            </button>
          </div>
        </div>
      </div>
    );
  } else if (delivered) {
    return (
      <div className="py-4">
        <div className="flex flex-col bg-white rounded-md shadow-md lg:w-[442px]">
          <div className="w-2 bg-success-600 flex-shrink-0" />
          <div className="flex flex-row justify-between w-full px-4">
            <div className="flex">
              <div className="flex-wrap">
                {booking.bookingItems.map((e, i) => {
                  return (
                    <div key={i}>
                      <span className="text-[#2D3142] font-bold">{e.name}</span>
                      <span> x{e.quantity}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="text-success-800 bg-success-50 py-[2px] px-[6px] h-7 rounded-md font-medium">
              Delivered
            </div>
          </div>
          <div className="flex flex-row justify-between text-graphite-900 px-4 pt-2">
            <div className="w-1/2">
              <span className="font-medium">Booking ID: </span>
              <span>{booking.id}</span>
            </div>
            <div className="w-1/2">
              <span className="font-medium">Type: </span>
              <span>{isGrabNGo ? "Grab n Go" : "PreStock"}</span>
            </div>
          </div>
          <div className="flex flex-row justify-between text-graphite-900 px-4 pb-4">
            <div className="w-1/2">
              <span className="font-medium">Order ID:</span>
              <span> {booking.orderId}</span>
            </div>
            <div className="w-1/2">
              <span className="font-medium">Name: </span>
              <span>{fullName}</span>
            </div>
          </div>
          <div className="px-4 pb-4">
            <div className="flex items-center text-success-800 bg-success-50 justify-center px-3 py-3 rounded-md w-full border border-gray">
              <CheckCircleIcon className="h-6 w-6 text-success-800" />
              <span className="pl-1"> Delivered</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (remove) {
    return (
      <div className="py-4">
        <div className="flex flex-col bg-white rounded-md shadow-md lg:w-[442px]">
          <div className="w-2 bg-destructive-600 flex-shrink-0" />
          <div className="flex flex-row justify-between w-full px-4">
            <div className="flex">
              <div className="flex-wrap">
                {booking.bookingItems.map((e, i) => {
                  return (
                    <div key={i}>
                      <span className="text-[#2D3142] font-bold">{e.name}</span>
                      <span> x{e.quantity}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="text-destructive-600 bg-destructive-50 py-[2px] px-[6px] h-7 rounded-md font-medium">
              Remove
            </div>
          </div>
          <div className="flex flex-row justify-between text-graphite-900 pt-2 px-4">
            <div className="w-1/2">
              <span className="font-medium">Booking ID: </span>
              <span>{booking.id}</span>
            </div>
            <div className="w-1/2">
              <span className="font-medium">Type: </span>
              <span>{isGrabNGo ? "Grab n Go" : "PreStock"}</span>
            </div>
          </div>
          <div className="flex flex-row justify-between text-graphite-900 pb-4 px-4">
            <div className="w-1/2">
              <span className="font-medium">Order ID:</span>
              <span> {booking.orderId}</span>
            </div>
            <div className="w-1/2">
              <span className="font-medium">Name: </span>
              <span>{fullName}</span>
            </div>
          </div>
          <div className="px-4 pb-4">
            <button
              disabled={loading}
              className="flex items-center text-white bg-destructive-600 justify-center px-3 py-3 rounded-md w-full md:w-[342px] lg:w-[342px] border border-gray"
              onClick={handleMarkAsRemoved}
            >
              <span className="pl-1">
                {loading ? "Loading..." : "Mark as removed"}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
}
